import React from "react";

import { SeoComponent } from "../../components/SeoComponent";
import { SingleTeam } from "../../components/StatisticsCard";
import { useAllStats } from "../../hooks/useAllStats";
import { logosForFullName } from "../../util/logos";

const SingleTeamStatistics = ({ rank, teamName, val }) => {
  return (
    <div className="flex w-full h-10 py-2 px-4 border border-solid">
      <div className="w-4 mr-4 text-gray-500 text-sm">{rank}</div>
      <div className="w-18 grow flex text-sm">{teamName}</div>
      <div className="flex items-center">
        <img
          width="25px"
          height="25px"
          src={logosForFullName[teamName]}
          alt={teamName}
        />
      </div>
      <div className="w-16 ml-4 justify-end flex text-sm font-bold">{val}</div>
    </div>
  );
};

const Stat = (props) => {
  const allStats = useAllStats();
  const stat = allStats.find((item) => item.slug === props.params.slug);
  const statistics = stat.statistics;

  return (
    <div className="flex flex-col w-full h-full items-center dark:bg-gray-900 dark:text-white">
      <h1 className="text-pink-500 font-bold text-center lg:mt-0 mt-4 mb-4">
        {stat.label} İstatistiği
      </h1>
      {stat.statistics ? (
        <div className="w-10/12 lg:w-10/12 lg:max-w-4xl max-w-5xl">
          <div className="flex flex-col items-center lg:flex-row w-full lg:justify-between">
            <div className="w-full lg:w-72">
              <SingleTeam
                statisticsName={stat.label}
                rank={1}
                big={true}
                name={statistics[0].team}
                value={statistics[0].val}
                textToAppend={stat.textToAppend}
                teamName={statistics[0].team}
              />
            </div>
            <div className="hidden lg:visible lg:flex lg:w-72">
              <SingleTeam
                statisticsName={stat.label}
                rank={2}
                big={true}
                name={statistics[1].team}
                value={statistics[1].val}
                textToAppend={stat.textToAppend}
                teamName={statistics[1].team}
              />
            </div>
            <div className="hidden lg:visible lg:flex lg:w-72">
              <SingleTeam
                statisticsName={stat.label}
                rank={3}
                big={true}
                name={statistics[2].team}
                value={statistics[2].val}
                textToAppend={stat.textToAppend}
                teamName={statistics[2].team}
              />
            </div>
          </div>
          <div className="w-full h-full my-4 items-center flex flex-col">
            {statistics.map((item, ind) => {
              if (ind > 0 && ind < 3) {
                return (
                  <div className="flex w-full lg:hidden" key={ind}>
                    <SingleTeamStatistics
                      rank={ind + 1}
                      teamName={item.team}
                      val={item.val}
                    />
                  </div>
                );
              }
              if (ind > 2) {
                return (
                  <SingleTeamStatistics
                    key={ind}
                    rank={ind + 1}
                    teamName={item.team}
                    val={item.val}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-full">
          Yeni sezon istatistikleri ilk hafta bitince...
        </div>
      )}
    </div>
  );
};

export default Stat;

export const Head = ({ params }) => {
  const stat = useAllStats().find((item) => item.slug === params.slug);
  return (
    <SeoComponent
      title={`${stat.label} İstatistiği`}
      description={`Türkiye Futbol Süper Ligi 2024-25 Sezonu ${stat.label} İstatistiği takım sıralaması`}
      keywords={`${stat.label}, Adana Demirspor, Alanyaspor, Antalyaspor, Başakşehir, Basaksehir, Beşiktaş, Besiktas, Bodrumspor, Eyüpspor,
      Eyupspor, Galatasaray, Fenerbahçe, Gaziantep, Gaziantep FK, Goztepe, Göztepe, Hatayspor, Kasımpaşa, Kasimpasa, Konyaspor,
      Rizespor, Sivasspor, Trabzonspor, Süperlig, Superlig, ensuperlig, ensüperlig`}
    />
  );
};
